import React from "react";
import "../styles/_footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__section footer__sales">
        <h4>SALES CENTER</h4>
        <p>
          Capital Business Park, 26th July Corridor Plot No.15 & 16, Building 9,
          6th Floor, Sheikh Zayed City, Giza, Egypt 11566
        </p>
      </div>
      <div className="footer__section footer__email">
        <h4>Email</h4>
        <p>
          <a href="mailto:info@owest.com.eg">info@owest.com.eg</a>
        </p>
      </div>
      <div className="footer__section footer__call">
        <h4>Call Center</h4>
        <p>16595</p>
      </div>
    </footer>
  );
};

export default Footer;
