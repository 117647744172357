import React from "react";
import "../styles/_navbar.scss";
import MainLogo from "../assets/images/orascom.png"

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <img src= {MainLogo} alt="O West Logo" />
      </div>
    </nav>
  );
};

export default Navbar;
